<template>
  <div class="lawEnforcement-escalation">
    <lawEnforcementUser></lawEnforcementUser>
    <div class="lawEnforcement-escalation-c">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="lawEnforcement-escalation-b">
      <div class="lawEnforcement-escalation-bt">
        <div class="item" @click="upload">
          <div class="item-l">事件上报</div>
          <div class="item-r"><img src="./img/upload.png" alt="" /></div>
        </div>
        <div class="item" @click="myUpload">
          <div class="item-l">我的上报</div>
          <div class="item-r"><img src="./img/my.png" alt="" /></div>
        </div>
      </div>
      <div class="lawEnforcement-escalation-bb">
        <div class="title">我的最近上报</div>
        <div class="ul">
          <div
            class="li"
            v-for="(item, index) in oList"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="li-t">
              <div class="li-tl">{{ item.orderTitle }}</div>
              <div :class="['li-tr', 'te' + item.orderStatus]">
                {{ statusTypeMap[item.orderStatus] }}
              </div>
            </div>
            <div class="li-b">
              <div class="li-bl"><img :src="item.imageUrl" alt="" /></div>
              <div class="li-br">
                <div class="h4">{{ item.orderDisc }}</div>
                <div class="date">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { userTypeMap, userType } from "../map";
import { statusTypeMap } from "./map";
import { getOrderInfoListUrl } from "./api";
import lawEnforcementUser from "../component/user";
export default {
  name: "lawEnforcement-escalation",
  data() {
    return {
      userType,
      userTypeMap,
      statusTypeMap,
      isshow: false,
      oList: [],
      form: {
        type: "1",
      },
    };
  },
  components: {
    lawEnforcementUser,
  },
  computed: {
    ...mapState(["userId", "role"]),
  },
  async created() {},
  mounted() {
    this.getOrderInfoList();
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "escalationDetail",
        query: {
          orderId: item.orderId,
        },
      });
    },
    myUpload() {
      this.$router.push({
        name: "myEscalation",
      });
    },
    upload() {
      this.$router.push({
        name: "escalationForm",
      });
    },
    selectLi(item) {
      this.form.type = item.value;
      this.isshow = false;
    },
    selectType() {
      this.isshow = !this.isshow;
    },
    async getOrderInfoList() {
      let params = {
        searchCount: 3,
        role: this.role,
        orderType: "",
        area: "",
        userId: this.userId,
        orderStatus: "",
      };
      let res = await this.$axios.post(`${getOrderInfoListUrl}`, params);
      if (res.code == 200) {
        this.oList = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.lawEnforcement-escalation {
  background: #f8f8f8;
  padding: 20px 30px;
  min-height: 100vh;
  box-sizing: border-box;
  .lawEnforcement-escalation-t {
    padding: 20px 48px;
    background: #fff;
    display: flex;
    border-radius: 16px;
    position: relative;
    align-items: center;
    .img {
      width: 52px;
      height: 52px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      font-weight: 400;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
      margin: 0 30px 0 22px;
    }
    .arrow {
      width: 22px;
      height: 14px;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .typeUlList {
      position: absolute;
      bottom: -224px;
      left: 80px;
      width: 180px;
      background: #fff;
      box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
      .li {
        padding: 14px 24px;
        font-size: 32px;
        line-height: 50px;
      }
    }
  }
  .lawEnforcement-escalation-c {
    margin: 20px 0;
    padding: 20px 24px 40px 18px;
    background: #ffffff;
    border-radius: 16px;
    img {
      width: 100%;
    }
  }
  .lawEnforcement-escalation-b {
    padding: 32px 30px 120px;
    background: #ffffff;
    border-radius: 16px;
    .lawEnforcement-escalation-bt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item {
        width: 304px;
        height: 120px;
        background: #f4f6f9;
        border-radius: 16px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 26px 20px 14px 30px;
        .item-l {
          font-weight: 700;
          font-size: 28px;
          color: #343e63;
          line-height: 40px;
        }
        .item-r {
          width: 100px;
          height: 80px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .lawEnforcement-escalation-bb {
      .title {
        font-weight: 700;
        font-size: 32px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
        margin: 40px 0 18px;
        position: relative;
        &::after {
          content: "";
          width: 218px;
          height: 18px;
          position: absolute;
          left: -10px;
          bottom: 0;
          background: linear-gradient(
            270deg,
            #3d7bfa 0%,
            rgba(61, 123, 250, 0) 100%
          );
          border-radius: 9px;
        }
      }
      .ul {
        .li {
          margin-bottom: 20px;
          .li-t {
            padding: 18px 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid rgba(0, 0, 0, 0.05);
            .li-tl {
              font-weight: 700;
              font-size: 32px;
              color: #333333;
              line-height: 44px;
              position: relative;
              padding-left: 34px;
              &::before {
                content: "";
                width: 12px;
                height: 12px;
                background: #3d7bfa;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
            .li-tr {
              width: 120px;
              height: 40px;
              background: rgba(255, 206, 111, 0.2);
              border-radius: 4px;
              font-weight: 700;
              font-size: 24px;
              color: #ffce6f;
              line-height: 40px;
              text-align: center;
            }
            .te0 {
            }
            .te1 {
              width: 120px;
              height: 40px;
              background: rgba(61, 123, 250, 0.2);
              border-radius: 4px;
              font-weight: 700;
              font-size: 24px;
              color: #3d7bfa;
              line-height: 40px;
              text-align: center;
            }
            .te2 {
              width: 120px;
              height: 40px;
              background: rgba(61, 123, 250, 0.2);
              border-radius: 4px;
              font-weight: 700;
              font-size: 24px;
              color: #3d7bfa;
              line-height: 40px;
              text-align: center;
            }
          }
          .li-b {
            margin-top: 16px;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            .li-bl {
              width: 210px;
              height: 118px;
              margin-right: 14px;
              flex-shrink: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .li-br {
              .h4 {
                font-weight: 700;
                font-size: 26px;
                color: rgba(0, 0, 0, 0.85);
                line-height: 36px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .date {
                font-weight: 400;
                font-size: 24px;
                color: rgba(0, 0, 0, 0.5);
                line-height: 34px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
